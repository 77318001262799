import * as React from 'react';

import {Row, Image} from 'react-bootstrap';

import aghKnLogoWhite from '@images/agh-kn-logo.png';
import aghLogo from '@images/agh-logo.png';

export default function SmallFooter() {
  return (
    <>
      <Row className="small-footer">
        <Image src={aghKnLogoWhite} className="upscale" />
        <Image src={aghLogo} />
      </Row>
    </>
  );
}
