import * as React from 'react';
import {Link} from 'gatsby';

import {Navbar, Nav, Container, Image, Button, Row, Col} from 'react-bootstrap';
import {XLg, List} from 'react-bootstrap-icons';

import aghKnLogoColor from '@images/agh-kn-logo-color.svg';
import aghKnLogoWhite from '@images/agh-kn-logo.png';
import polishFlag from '@images/pl.svg';
import englishFlag from '@images/en.svg';

import WcagPicker from './wcag-picker';
import {useIntl, changeLocale, FormattedMessage} from 'gatsby-plugin-intl';

export default function Navigation({customPagesNav}) {
  const intl = useIntl();

  // Big menu & mobile menu logic
  const [mainMenuVisible, setMainMenuVisible] = React.useState(false);
  const [subMenuVisible, setSubMenuVisible] = React.useState(false);
  const [subMenuContents, setSubMenuContents] = React.useState(null);

  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  const [mobileSubMenuVisible, setMobileSubMenuVisible] = React.useState(null);

  const menuRef = React.useRef(null);
  const subMenuRef = React.useRef(null);

  const mobileSubMenuHandler = whichSubMenu => {
    if (whichSubMenu === mobileSubMenuVisible) setMobileSubMenuVisible(null);
    else setMobileSubMenuVisible(whichSubMenu);
  };

  const shouldDisplayMobileSubmenu = whichSubMenu => {
    return mobileSubMenuVisible === whichSubMenu;
  };

  const noScroll = () => {
    document.body.classList.toggle('noscroll');
  };

  React.useEffect(() => {
    const handleOutsideClick = event => {
      if (!menuRef.current.contains(event.target) && !subMenuRef.current.contains(event.target)) {
        if (mainMenuVisible && subMenuVisible) {
          setMainMenuVisible(false);
          setSubMenuVisible(false);
        } else if (mainMenuVisible && !subMenuVisible) {
          setMainMenuVisible(false);
        }
      }
    };

    if (mainMenuVisible || subMenuVisible) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [mainMenuVisible, subMenuVisible]);

  // const shouldDisplayCustomPages = () => {
  //   return Array.isArray(customPagesNav) && customPagesNav.length > 0;
  // };

  const renderNavigationLink = customPage => {
    if (!customPage.nameEn) customPage.nameEn = customPage.name;

    return (
      <a href={customPage.isExternal ? customPage.externalUrl : `/strony/${customPage.slug}`} key={customPage.slug}>
        {intl.locale === 'pl' ? customPage.name : customPage.nameEn}
      </a>
    );
  };

  const displayMenu = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      window.innerWidth < 900
    ) {
      setMobileMenuVisible(!mobileMenuVisible);
    } else {
      setMainMenuVisible(!mainMenuVisible);
    }
    if (subMenuVisible) setSubMenuVisible(false);
    noScroll();
  };

  const renderSubMenu = () => {
    switch (subMenuContents) {
      case 'kola-naukowe':
        return (
          <>
            <Link href={'/kola-naukowe'}>
              <FormattedMessage id="menu.list" />
            </Link>
            {customPagesNav?.filter(cp => cp.navigation === 'kola-naukowe').map(cp => renderNavigationLink(cp))}
          </>
        );
      case 'dokumenty':
        return <>{customPagesNav?.filter(cp => cp.navigation === 'dokumenty').map(cp => renderNavigationLink(cp))}</>;
      case 'konkursy':
        return <>{customPagesNav?.filter(cp => cp.navigation === 'konkursy').map(cp => renderNavigationLink(cp))}</>;
      case 'konferencje':
        return (
          <>
            <a href={'/konferencje-hutnicze'}>
              <FormattedMessage id="konfa.hutnicza.title" />
            </a>
            <a href={'http://www.knpg.agh.edu.pl/konferencja-sknpg/'} target="_blank">
              <FormattedMessage id="konfa.barborkowa.title" />
            </a>
            <a href={'http://skns.hoborskiego.agh.edu.pl/'} target="_blank">
              <FormattedMessage id="konfa.nauk-scislych.title" />
            </a>
            <a href={'/konferencje-studenckie'}>
              <FormattedMessage id="konfa.studencka.title" />
            </a>
          </>
        );
      case 'studenckie-centrum-konstrukcyjne':
        return (
          <>
            {customPagesNav
              ?.filter(cp => cp.navigation === 'studenckie-centrum-konstrukcyjne')
              .map(cp => renderNavigationLink(cp))}
          </>
        );
      case 'rada-kol-naukowych':
        return (
          <>
            {customPagesNav?.filter(cp => cp.navigation === 'rada-kol-naukowych').map(cp => renderNavigationLink(cp))}
          </>
        );
      default:
        return null;
    }
  };

  // WCAG logic
  const lsContrast = 'agh-kn-css-class';
  const wcagContrasts = ['wcag-contrast-1', 'wcag-contrast-2'];

  const [val, setVal] = React.useState(0);
  const isHighContrast = typeof window !== 'undefined' ? !!localStorage.getItem(lsContrast) : false;

  React.useEffect(() => {
    if (localStorage.getItem(lsContrast)) {
      document.body.classList.add(localStorage.getItem(lsContrast));
    }
  }, []);

  const removeAllWcagClasses = () => {
    wcagContrasts.map(cssClass => document.body.classList.remove(cssClass));
  };

  const handleWcag = arg => {
    removeAllWcagClasses();

    if (arg) {
      document.body.classList.add(arg);
      localStorage.setItem(lsContrast, arg);
    } else {
      localStorage.removeItem(lsContrast);
    }

    setVal(val + 1); // To force rerender
  };

  return (
    <nav className="navigation">
      <Navbar bg="white" fixed="top" collapseOnSelect expand="lg">
        <Container>
          <Nav>
            <WcagPicker handleWcag={handleWcag} wcagContrasts={wcagContrasts} />
            <LanguageSwitcher mobileMenuVisible={mobileMenuVisible} />
            <a className="text-end">
              {mainMenuVisible === true || mobileMenuVisible === true ? (
                <Button className="navigation__menu-button" onClick={displayMenu}>
                  <XLg />
                </Button>
              ) : (
                <Button className="navigation__menu-button" variant="link" onClick={displayMenu}>
                  <List />
                </Button>
              )}
            </a>
          </Nav>
          <Navbar.Brand>
            <Link to="/">
              <Image src={!isHighContrast ? aghKnLogoColor : aghKnLogoWhite} className="navigation__logo"></Image>
            </Link>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <div className="navigation__menu" style={{left: mainMenuVisible ? '0' : '-230px'}}>
        <Container>
          <Row>
            <Col md={12} lg={4} className="navigation__menu__main" ref={menuRef}>
              <a href="/aktualnosci">
                <FormattedMessage id="menu.news" />
              </a>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setSubMenuVisible(true);
                  setSubMenuContents('kola-naukowe');
                }}
              >
                <FormattedMessage id="menu.clubs" />
              </a>

              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setSubMenuVisible(true);
                  setSubMenuContents('konferencje');
                }}
              >
                <FormattedMessage id="menu.conf" />
              </a>

              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setSubMenuVisible(true);
                  setSubMenuContents('konkursy');
                }}
              >
                <FormattedMessage id="menu.comp" />
              </a>

              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setSubMenuVisible(true);
                  setSubMenuContents('dokumenty');
                }}
              >
                <FormattedMessage id="menu.docs" />
              </a>
              <a
                href="https://dss.agh.edu.pl/sck-studenckie-centrum-konstrukcyjne"
                target="_blank"
                rel="noreferrer"
                title="SCK AGH"
              >
                <FormattedMessage id="menu.sck" />
              </a>
              <a href="https://www.facebook.com/RKNAGH" target="_blank" rel="noreferrer" title="RKN AGH">
                <FormattedMessage id="menu.rkn" />
              </a>

              <a href="https://samorzad.agh.edu.pl/">URSS AGH</a>
              <a href="/kontakt">
                <FormattedMessage id="menu.contact" />
              </a>
            </Col>
            <Col
              md={6}
              lg={4}
              className="navigation__menu__sub-menu"
              style={{left: subMenuVisible ? '230px' : '-250px'}}
              onClick={() => setSubMenuVisible(true)}
              ref={subMenuRef}
            >
              {renderSubMenu()}
            </Col>
          </Row>
        </Container>
      </div>

      {/* MOBILE */}
      <div className="navigation__mobile-menu" style={{display: mobileMenuVisible ? 'block' : 'none'}}>
        <Container>
          <Row>
            <Col md={12} lg={4} className="navigation__mobile-menu__main">
              <a href="/aktualnosci">Aktualności</a>
              <p
                className="navigation__mobile-menu__main__submenu-button"
                onClick={() => mobileSubMenuHandler('kola-naukowe')}
              >
                <FormattedMessage id="menu.clubs" />
              </p>
              <div
                className="navigation__mobile-menu__main__submenu"
                style={{display: shouldDisplayMobileSubmenu('kola-naukowe') ? 'flex' : 'none'}}
              >
                <Link to={'/kola-naukowe'}>
                  <FormattedMessage id="menu.list" />
                </Link>
                {customPagesNav?.filter(cp => cp.navigation === 'kola-naukowe').map(cp => renderNavigationLink(cp))}
              </div>
              <p
                className="navigation__mobile-menu__main__submenu-button"
                onClick={() => mobileSubMenuHandler('konferencje')}
              >
                <FormattedMessage id="menu.conf" />
              </p>
              <div
                className="navigation__mobile-menu__main__submenu"
                style={{display: shouldDisplayMobileSubmenu('konferencje') ? 'flex' : 'none'}}
              >
                {customPagesNav?.filter(cp => cp.navigation === 'konferencje').map(cp => renderNavigationLink(cp))}
              </div>
              <p
                className="navigation__mobile-menu__main__submenu-button"
                onClick={() => mobileSubMenuHandler('konkursy')}
              >
                <FormattedMessage id="menu.comp" />
              </p>
              <div
                className="navigation__mobile-menu__main__submenu"
                style={{display: shouldDisplayMobileSubmenu('konkursy') ? 'flex' : 'none'}}
              >
                {customPagesNav?.filter(cp => cp.navigation === 'konkursy').map(cp => renderNavigationLink(cp))}
              </div>
              <p
                className="navigation__mobile-menu__main__submenu-button"
                onClick={() => mobileSubMenuHandler('dokumenty')}
              >
                <FormattedMessage id="menu.docs" />
              </p>
              <div
                className="navigation__mobile-menu__main__submenu"
                style={{display: shouldDisplayMobileSubmenu('dokumenty') ? 'flex' : 'none'}}
              >
                {customPagesNav?.filter(cp => cp.navigation === 'dokumenty').map(cp => renderNavigationLink(cp))}
              </div>
              <p
                className="navigation__mobile-menu__main__submenu-button"
                onClick={() => mobileSubMenuHandler('studenckie-centrum-konstrukcyjne')}
              >
                <FormattedMessage id="menu.sck" />
              </p>
              <div
                className="navigation__mobile-menu__main__submenu"
                style={{display: shouldDisplayMobileSubmenu('studenckie-centrum-konstrukcyjne') ? 'flex' : 'none'}}
              >
                {customPagesNav
                  ?.filter(cp => cp.navigation === 'studenckie-centrum-konstrukcyjne')
                  .map(cp => renderNavigationLink(cp))}
              </div>
              <p
                className="navigation__mobile-menu__main__submenu-button"
                onClick={() => mobileSubMenuHandler('rada-kol-naukowych')}
              >
                <FormattedMessage id="menu.rkn" />
              </p>
              <div
                className="navigation__mobile-menu__main__submenu"
                style={{display: shouldDisplayMobileSubmenu('rada-kol-naukowych') ? 'flex' : 'none'}}
              >
                {customPagesNav
                  ?.filter(cp => cp.navigation === 'rada-kol-naukowych')
                  .map(cp => renderNavigationLink(cp))}
              </div>
              <a href="https://samorzad.agh.edu.pl/">URSS AGH</a>
              <a href="/kontakt">Kontakt</a>
            </Col>
          </Row>
        </Container>
      </div>
    </nav>
  );
}

const LanguageSwitcher = () => {
  const intl = useIntl();

  const handleLanguageSwitch = lang => {
    changeLocale(lang);
  };

  return (
    <div className="language-switcher">
      {intl.locale === 'pl' ? (
        <button onClick={() => handleLanguageSwitch('en')}>
          <Image quality={100} width={40} height={30} src={englishFlag} className="navigation__logo" />
        </button>
      ) : (
        <button onClick={() => handleLanguageSwitch('pl')}>
          <Image quality={100} width={40} height={30} src={polishFlag} className="navigation__logo" />
        </button>
      )}
    </div>
  );
};
