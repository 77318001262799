import * as React from 'react';
import {Helmet} from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@sass/main.sass';

import appleTouchIcon from '@images/favicon/apple-touch-icon.png';
import favicon32x32 from '@images/favicon/favicon-32x32.png';
import favicon16x16 from '@images/favicon/favicon-16x16.png';
import ogImage from '@images/meta/og-image.png';

export default function Layout({children}) {
  return (
    <>
      <Helmet>
        <html lang="pl" />
        <meta charSet="utf-8" />
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#3d7a4d"></meta>
        <meta
          name="description"
          content="Studenckie Koła Naukowe są doskonałym miejscem rozwoju dla studentów, którzy pogłębiając swoją wiedzę teoretyczną, równocześnie chcą rozwijać swoje pasje, umiejętności praktyczne i więzi społeczne. Stanowią one szkołę talentów i „kuźnię” wiodących kadr naukowych i inżynierskich naszego kraju."
        ></meta>
        <meta
          property="og:description"
          content="Studenckie Koła Naukowe są doskonałym miejscem rozwoju dla studentów, którzy pogłębiając swoją wiedzę teoretyczną, równocześnie chcą rozwijać swoje pasje, umiejętności praktyczne i więzi społeczne. Stanowią one szkołę talentów i „kuźnię” wiodących kadr naukowych i inżynierskich naszego kraju."
        />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <div className="layout">{children}</div>
    </>
  );
}
