import * as React from 'react';

export default function Navigation({handleWcag, wcagContrasts}) {
  return (
    <div className="navigation__wcag-picker">
      <button className="wcag-button-none" onClick={() => handleWcag(null)}>
        A
      </button>
      <button className="wcag-button-1" onClick={() => handleWcag(wcagContrasts[0])}>
        A
      </button>
      <button className="wcag-button-2" onClick={() => handleWcag(wcagContrasts[1])}>
        A
      </button>
    </div>
  );
}
