import * as React from 'react';

import {Container, Row} from 'react-bootstrap';

// import BigFooter from './big-footer';
import SmallFooter from './small-footer';

export default function Footer() {
  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <>
      <footer>
        <Container>
          <Row>
            <SmallFooter />
          </Row>
        </Container>
      </footer>
      <div className="footer-black d-none d-md-block">
        <Container className="footer-black__container">
          <span>&copy; 2022 Koła Naukowe AGH</span>
          {/* eslint-disable-next-line */}
          <a className="white" onClick={scrollTop} aria-hidden="true">
            Do góry &uarr;
          </a>
        </Container>
      </div>
    </>
  );
}
